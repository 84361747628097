header.main-header {
    position: relative;
    background-color: $main-header-bg;
    height: 90px;
    a {
        user-select: none;
    }
    .nav-ul-survey {
        display: none;
    }
}

nav.main-nav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.nav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
}

#nav-ul-wrapper {
    position: absolute;
    background-color: $dark-background;
    padding: 2rem;
    top: 100%;
    right: 0;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;

    &.open {
        transform: translateX(0);
        border-left: 2px dashed #fff;
        border-top: 2px dashed #fff;
        border-bottom: 2px dashed #fff;
        z-index: 100;
    }
}

header.is-live {
    .nav-ul-survey {
        display: list-item;
    }
}
.nav-ul {
    text-align: right;
    //	padding-left: 15px;
}
.nav-ul-item {
    margin-bottom: 15px;
}

.nav-ul-link {
    color: $text-color;
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;

    // &:before {
    //     content: attr(title);
    //     font-weight: 600;
    //     display: block;
    //     height: 1px;
    //     color: transparent;
    //     overflow: hidden;
    //     visibility: hidden;
    //     margin-bottom: -1px;
    // }

    &:hover,
    &:focus {
        color: $text-color;
        font-weight: 600;
    }

    //&.nav-ul-link-user {}

    &.nav-ul-link-active {
        font-weight: bold;
        position: relative;
        //text-decoration: underline;

        &:after {
            content: '';
            width: 45px;
            height: 3px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.nav-ul-link-user,
.link-user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }
}

.nav-logout {
    color: $text-color;
}

.ico-user {
    background-image: url('/assets/img/user-icon-white.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 8px;
}

#nav-toggler {
    width: 40px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    appearance: none;
    background-image: none;
    background: transparent;
    border: 0px none;
    border-radius: 0;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $text-color;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 12px;
        width: 70%;
    }

    span:nth-child(4) {
        top: 24px;
        width: 50%;
    }

    &.open {
        span:nth-child(1) {
            top: 18px;
            width: 0%;
            right: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
            width: 100%;
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
            width: 100%;
        }

        span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
}

.nav-ul-item-dd.no-hover {
    #nav-logout {
        display: none !important;
    }

    .nav-ul-link {
        cursor: default;
    }
    .nav-ul-link:hover {
        font-weight: normal;
    }
}

.main-header.main-header-menu > nav.main-nav {
    margin: auto;
    max-width: 66.66666667%;
    display: flex;
    justify-content: space-between;
}
