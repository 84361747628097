$countdown-base-font: 30px;

#time2go {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
    padding-top: 45px;
    //	padding: 15px;

    .t2-counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        //padding: 0 12px;
        //max-width: 160px;

        .short {
            text-transform: uppercase;
            font-size: $countdown-base-font;
        }

        .long {
            font-weight: 300;
            display: none;
            text-transform: uppercase;
            font-size: $countdown-base-font;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &:last-child {
            margin-right: 0;

            .t2-digit:after {
                content: '';
            }
        }
        // &:first-child {

        //     .t2-digit:after {
        //         content: '';
        //     }
        // }
    }
    .t2-part {
        line-height: 1;
        //background-color: $text-color;
        //color: $text-reverse;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .t2-digit {
        font-size: $countdown-base-font;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
        width: 100%;
        padding-bottom: 3px;
        position: relative;
        font-weight: 900;

        //&:after {
        //    content: ':';
        //    position: absolute;
        //    right: -5px;
        //}
    }
}

@media (min-width: 375px) {
    #time2go {
        .t2-counter {
            //padding: 0 15px;

            .long {
                font-size: $countdown-base-font * 1.3;
            }
        }

        .t2-digit {
            font-size: $countdown-base-font * 1.3;
            width: 80px;

            &:after {
            }
        }
    }
}

@media (min-width: 540px) {
    #time2go {
        //padding: 25px 5vw;
        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: $countdown-base-font * 1.4;
            }
        }

        .t2-digit {
            font-size: $countdown-base-font * 1.4;
            width: 100px;

            &:after {
            }
        }
    }
}

@media (min-width: 768px) {
    #time2go {
        //padding: 50px 10vw;

        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: $countdown-base-font * 1.5;
            }
        }

        .t2-digit {
            font-size: $countdown-base-font * 1.5;
            width: 120px;

            &:after {
            }
        }
    }
}

@media (min-width: 992px) {
    #time2go {
        //padding: 25px 0;

        .t2-counter {
            //padding: 0 35px;
            width: 100%;

            .short {
                display: none;
            }

            .long {
                font-size: $countdown-base-font * 1.2;
                display: block;
            }
        }

        .t2-digit {
            font-size: $countdown-base-font * 1.4;
            width: 180px;

            &:after {
                right: -20px;
            }
        }
    }
}

@media (min-width: 1200px) {
    #time2go {
        .t2-counter {
            .long {
                font-size: $countdown-base-font * 1.4;
            }
        }

        .t2-digit {
            font-size: $countdown-base-font * 1.6;
        }
    }
}
