$bottom-logo-width: 100px;
$bottom-logo-width-500: 120px;
$bottom-logo-width-768: 150px;
$bottom-logo-width-992: 180px;
$bottom-logo-width-max: 210px;

#footer {
    position: relative;

    .bottom-line {
        height: 5px;
        background-color: $text-highlight;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .footer-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;

        .fm-line {
            border-bottom: 1px solid $line-color;
            line-height: 0.1em;
            flex-grow: 1;
        }

        .fm-msg {
            padding: 0 1rem;
        }
    }

    .footer-partners {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &.with-logo {
        .inner {
            padding: 0 15px;
            max-width: 400px;
            margin: 0 auto;
        }

        .bottom-logo {
            position: absolute;
            right: 0;
            bottom: 0px;
            width: $bottom-logo-width;
        }

        .footer-message {
            font-size: 14px;
        }

        .bottom-line {
            width: calc(100vw - #{$bottom-logo-width});
        }

        .footer-partners {
            padding-top: 1rem;
            padding-bottom: 4rem;
        }
    }

    &.no-logo {
        .inner {
            padding: 15px;
            width: auto;

            max-width: 600px;
            margin: 0 auto;
        }

        .footer-message {
            font-size: 14px;
        }

        .footer-partners {
            padding-top: 0.5rem;
            padding-bottom: 1rem;
        }

        .bottom-logo {
            display: none;
        }

        .bottom-line {
            width: 100%;
        }
    }
}

#footer.footer-img {
    position: relative;
    text-align: center;
    padding: 1rem 0;

    .footer-logo {
        display: inline-block;
        margin: 0 auto;
        width: 75px;
    }
}

#footer.footer-partners {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .footer-partner {
        flex: 1 0 33%;

        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 25px;
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 500px) {
    #footer {
        &.with-logo {
            .wrapper {
                width: calc(100vw - #{$bottom-logo-width-500});
                text-align: center;
            }

            .inner {
                padding: 0;
                margin: 0 auto;
                max-width: 370px;
            }

            .footer-partners {
                padding-top: 0.5rem;
                padding-bottom: 1rem;
            }

            .bottom-logo {
                width: $bottom-logo-width-500;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-500});
            }
        }

        &.no-logo {
            .footer-message {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #footer {
        &.with-logo {
            .wrapper {
                width: calc(100vw - #{$bottom-logo-width-768});
            }

            .inner {
                max-width: 500px;
            }

            .footer-partners {
            }

            .bottom-logo {
                width: $bottom-logo-width-768;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-768});
            }
        }
    }

    #footer.footer-img {
        .footer-logo {
            width: 100px;
        }
    }

    #footer.footer-partners {
        justify-content: space-between;
        .footer-partner {
            flex: 1;
            max-width: 120px;
        }
    }
}

@media screen and (min-width: 992px) {
    #footer {
        &.with-logo {
            .wrapper {
                width: 100%;
            }

            .inner {
                max-width: 500px;
            }

            .footer-partners {
            }

            .bottom-logo {
                width: $bottom-logo-width-992;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-992});
            }
        }
    }

    #footer.footer-img {
        text-align: left;
        padding: 1rem 2vw;

        .footer-logo {
            display: inline-block;
            margin: 0;
            width: 100px;
        }
    }

    #footer.footer-partners {
        padding-left: 2vw;
        padding-right: 2vw;

        .footer-partner {
            max-width: 150px;
        }
    }
}

@media screen and (min-width: 1200px) {
    #footer {
        &.no-logo {
            .inner {
                padding: 0 15px;
                max-width: 600px;
                margin: 0 auto;
            }

            .footer-message {
                font-size: 16px;
            }
        }

        &.with-logo {
            .footer-message {
                font-size: 16px;
            }

            .inner {
            }

            .bottom-logo {
                width: $bottom-logo-width-max;
            }

            .footer-partners {
                padding-bottom: 2rem;
            }

            .bottom-line {
                width: calc(100vw - #{$bottom-logo-width-max});
            }
        }
    }

    #footer.footer-partners {
        padding-left: 4vw;
        padding-right: 4vw;

        .footer-partner {
            max-width: 180px;
        }
    }
}
