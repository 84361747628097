.thankyou {
    #logo-box {
        max-width: 300px;
        margin-top: 4rem;
    }

    a{
        font-size: 1.5rem;
        margin-top: 8rem;
        color: #dbdbdb;
        text-decoration: underline;
    }
    .register-text{
        font-size: 1.5rem;
        color: #dbdbdb;
    }
    a:hover {
        font-weight: 800;
    }
}
